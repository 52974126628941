@use 'src/styles/mixins';
@use 'src/styles/variables' as *;
@use 'variables' as *;

.starRating {
  width: 100%;
  display: flex;
  gap: 8px;
  align-items: center;

  @media (max-width: $media-request-max-width-mobile) {
    flex-direction: column;
  }

  .label {
    width: fit-content;
    @include mixins.fnt($size: 16, $height: 24, $color: $text-color-secondary, $weight: 400);
  }
}