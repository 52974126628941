@use "/src/styles/" as *;

.map-info {
    display: flex;
    padding: 12rem;
    flex-direction: column;
    align-items: flex-start;
    gap: 8rem;
    border-radius: 12rem;
    background: $gray-40;
    margin-top: 32rem;
    &__text {
        @include fnt(12, 16);
    }
}
.map-page-title {
    display: block;
    @include fnt(32, 48, $black, 600);
}
.map-page-title-h2 {
    display: block;
    max-width: 860rem;
    @include fnt(24, 32, $black, 600);
}
.stupid-map-info {
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding: 20rem 24rem;
    column-gap: 32rem;
    row-gap: 8rem;
    border-radius: 12rem;
    background: $gray-40;
    &__text {
        @include fnt(16, 20);
        &._bold {
            @include fnt(16, 20, $weight: 600);
        }
    }
    &__top-text {
        display: block;
        margin: 16rem 0;
        @include fnt(20, 32);
    }
    &__bottom-text {
        display: block;
        margin: 16rem 0 24rem;
        @include fnt(16, 24);
        max-width: 660rem;
    }
    @include tablet {
        grid-template-columns: 100%;
    }
}
.map-page-content {
    p {
        @include fnt(16, 20);
        margin-top: 16rem;
        &._bold,
        ._bold {
            @include fnt(16, 24, $weight: 600);
        }
        a {
            @include fnt(16, 24, $main);
            text-decoration: none;
        }
    }
    b {
        @include fnt(16, 24, $weight: 600);
    }
    ul {
       padding: 0 24rem;
    }
}