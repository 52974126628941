@use 'src/styles/mixins';
@use 'src/styles/variables' as *;
@use 'variables' as *;

@mixin changeSvg($stroke: null, $fill: null) {
  svg {
    * {
      transition: stroke 200ms ease-out, fill 200ms ease-out;
      @if ($stroke != null) {
        stroke: $stroke;
        fill: $fill;
      }
    }

    @content;
  }
}

.star {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  outline: none;
  border: none;
  text-decoration: none;
  background: none;
  cursor: pointer;

  @include changeSvg($stroke: $gray-80, $fill: $gray-80) {
    width: 32px;
    height: 32px;
  }

  &.active {
    @include changeSvg($stroke: #FAC515, $fill: #FAC515);
  }

  &:hover, &.hover {
    @media (min-width: $media-request-max-width-mobile) {
      @include changeSvg($stroke: #BABCC2, $fill: #BABCC2);

      &.active {
        @include changeSvg($stroke: #EAAA08, $fill: #EAAA08);
      }
    }
  }
}