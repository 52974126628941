@use 'src/styles/mixins';
@use 'src/styles/variables' as *;
@use 'variables' as *;

.customerServicePolling {
  width: 100vw;
  height: 100vh;

  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 304px;
  background-color: $gray-20;

  overflow-y: scroll;

  @media (max-width: $media-request-max-width-laptop) {
    padding: 0 50px;
  }
  @media (max-width: $media-request-max-width-mobile) {
    padding: 0 20px;
    height: 100dvh;
  }

  .header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px 0 16px 0;

    .logo {
      flex: none;
      width: fit-content;
      height: fit-content;
      display: flex;
      align-items: end;
      gap: 12px;

      .label {
        width: 137px;
        @include mixins.fnt($size: 12, $height: 14, $color: $text-color-secondary, $weight: 400);


        @media (max-width: $media-request-max-width-mobile) {
          display: none;
        }
      }

      svg {
        flex: none;
      }
    }
  }

  .main {
    flex: 1;
    width: 100%;

    display: flex;
    justify-content: center;
    align-items: center;

    .poll {
      width: 620px;
      display: flex;
      flex-direction: column;
      padding: 40px 48px 48px 48px;
      border-radius: 24px;
      gap: 24px;

      background-color: $white;
      box-shadow: 0 8px 8px -4px #10182808, 0 20px 24px -4px #10182814;


      @media (max-width: $media-request-max-width-mobile) {
        padding: 20px 24px 24px 24px;
      }
    }

    .field {
      height: 92px;
    }
  }

  .footer {
    flex: none;
    width: 295px;
    padding: 16px 0 16px 0;
    overflow: hidden;
    text-align: center;
    text-decoration: none;
    cursor: pointer;
    @include mixins.fnt($size: 14, $height: 24, $color: $text-color-secondary, $weight: 400);

    @media (max-width: $media-request-max-width-laptop) {
      width: 100%;
    }
  }
}

.text {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 12px;

  .title {
    width: 100%;

    .titleHint {
      width: 100%;
      @include mixins.fnt($size: 14, $height: 20, $color: $text-color-secondary, $weight: 400);
    }

    .titleText {
      width: 100%;
      @include mixins.fnt($size: 24, $height: 32, $color: $text-color, $weight: 600);
    }
  }

  .hint {
    width: 100%;
    @include mixins.fnt($size: 16, $height: 24, $color: $text-color, $weight: 400);
  }
}