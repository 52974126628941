@use 'src/styles/mixins';
@use 'src/styles/variables' as *;

.hasProblem {
  display: flex;
  flex-direction: column;
  gap: 16px;

  .label {
    @include mixins.fnt($size: 16, $height: 24, $color: mixins.$text-color, $weight: 600);
  }

  .fields {
    display: flex;
    gap: 24px;
  }
}